import React from 'react';
import {
  ModalClose,
  PopupWrapper,
  HeaderWrapper,
  ContentWrapper,
  Title,
  ButtonGroup,
  CancelButton,
  ConfirmButton,
  ContentWrapperProps,
} from './Popup.style';
import { CloseIcon } from 'components/AllSvgIcon';
import Loader from 'components/Loader/Loader';

type YesNoProps = {
  closeModal: any;
  title: string;
  noDisabled?: boolean;
  noLoading?: boolean;
  noText?: string;
  noAction?: () => void;
  closeOnNo?: boolean;
  yesDisabled?: boolean;
  yesLoading?: boolean;
  yesText?: string;
  yesAction?: () => void;
  closeOnYes?: boolean;
  showClose?: boolean;
  stickyButtons?: boolean;
  children?: any;
} & ContentWrapperProps;

export const YesNo: React.FC<YesNoProps> = ({
  closeModal,
  title,
  noDisabled = false,
  noLoading = false,
  noText,
  noAction,
  closeOnNo = true,
  yesDisabled = false,
  yesLoading = false,
  yesText,
  yesAction,
  closeOnYes = true,
  showClose = true,
  stickyButtons = false,
  children,
  $categoryPopupMaxHeight,
}) => {
  const handleNo = (e: any) => {
    if (noDisabled || noLoading || yesLoading) return;
    if (noAction) noAction();
    if (closeOnNo) closeModal();
  };

  const handleYes = (e: any) => {
    if (yesDisabled || noLoading || yesLoading) return;
    if (yesAction) yesAction();
    if (closeOnYes) closeModal();
  };

  return (
    <PopupWrapper>
      <HeaderWrapper>
        {showClose && (
          <ModalClose onClick={() => closeModal()}>
            <CloseIcon />
          </ModalClose>
        )}

        <Title>{title}</Title>
      </HeaderWrapper>

      <ContentWrapper
        className={stickyButtons ? 'scroll' : ''}
        $categoryPopupMaxHeight={$categoryPopupMaxHeight}
      >
        {children}
      </ContentWrapper>

      {(noText || yesText) && (
        <ButtonGroup className={stickyButtons ? 'sticky' : ''}>
          {noText && noLoading && (
            <CancelButton>
              <Loader style={{ margin: 'auto' }} />
            </CancelButton>
          )}

          {noText && !noLoading && (
            <CancelButton
              onClick={handleNo}
              className={noDisabled ? 'disabled' : ''}
            >
              {noText}
            </CancelButton>
          )}

          {yesLoading && (
            <ConfirmButton>
              <Loader style={{ margin: 'auto' }} />
            </ConfirmButton>
          )}

          {yesText && !yesLoading && (
            <ConfirmButton
              onClick={handleYes}
              className={yesDisabled ? 'disabled' : ''}
            >
              {yesText}
            </ConfirmButton>
          )}
        </ButtonGroup>
      )}
    </PopupWrapper>
  );
};

export default YesNo;
